<template>
    <div v-if="currentBill" data-app class="vuetify bill-summary-content">
        <bill-summary-cost-summary
            @viewpaymentoptions="viewPaymentOptions()"
            @gotoaccountssummary="requireAuthedUserForLink('ProvidersSummary', {})"
            @viewaccountinfo="accountInfoBottomSheet = true"
            @viewbill="viewBill(currentBill.secureCode)"
            @watchstatementoverview="watchStatementOverview()"
            @composemessage="composeMessage"
            @redirecttofinancing="redirectToFinancing()"
            :account="account"
            :amountDue="currentBill.accountBalance.amount"
            :dueDate="currentBill.dueDate"
            :providerLocation="provider.name"
            :patientName="accountInfo.patientName"
            :accountNumber="accountInfo.accountNumber"
            :isDbu="currentBill.reflectsDbu()"
            :billAmount="currentBill.billAmount"
            :providerCanMessage="provider.canMessage"
            :showProviderDetailsButton="!currentBill.accountBalance.amount || Boolean(currentBill.accountDetails.financedBy) || currentBill.accountBalance.amount < 0"
            :hidePaymentPlanLink="!Boolean(currentUser)"
            :disablePaymentButton="!account.amountDue || Boolean(account.financedBy) || !account.provider.active || account.amountDue < 0"
            :showFinancingRedirectButton="account.canManageFinancedAccount"
            :sunday-sky-program-id="sundaySkyProgramId"
            :financedBy="account.financedBy"
            data-testid="bill-summary-cost-summary"
        ></bill-summary-cost-summary>
        <div v-if="shouldShowBillDetailsLabel" class="bill-summary-mobile">
            <span class="vuetify label">{{ $translate('labels.billDetails') }}</span>
        </div>
        <div v-if="currentBillSubAccountsCount>1">
            <div v-for="(subAccount) in subAccounts" :key="subAccount.id" @click="viewSubAccount(subAccount)">
                <bill-summary-sub-account-card
                    :subAccount="subAccount">
                </bill-summary-sub-account-card>
            </div>
        </div>
        <div v-if="currentBillSubAccountsCount<=1">
            <sub-account-services-card
                :services="services"
                data-testid="bill-summary-sub-account-services-card"
            ></sub-account-services-card>
            <appointment-info-card
                :appointmentInfo="getAppointmentInfo()"
                data-testid="bill-summary-appointment-info-card">
            </appointment-info-card>
        </div>
        <base-bottom-sheet v-model="accountInfoBottomSheet">
            <card-header
                :title="$translate('labels.accountInfo')"
                icon="mdi-folder-plus-outline"
                class="sheet-card-header"
            ></card-header>
            <account-info
                :accountInfo="accountInfo"
                data-testid="bill-summary-account-info-mobile"
            ></account-info>
        </base-bottom-sheet>
        <base-bottom-sheet v-model="subAccountDetailsSheet">
            <sub-account-details-sheet
                @close="subAccountDetailsSheet = false"
                @pay-sub-account="paySubAccount()"
                @viewbill="viewBill(currentBill.secureCode)"
                :subAccount="visibleSubAccount"
                :accountInfo="accountInfo"
                :bill="currentBill"
                :services="subAccountServices"
                :subAccountPaymentsEnabled="account.provider.subAccountPaymentsEnabled"
            ></sub-account-details-sheet>
        </base-bottom-sheet>
        
        <base-dialog v-model="videoBillModal" :scrollable="false" width="auto" content-class="sunday-sky-dialog">
            <sunday-sky-player :program-id="sundaySkyProgramId" :poster-url="sundaySkyPosterUrl" type="bill" @exit="closeVideo"></sunday-sky-player>
        </base-dialog>

        <video-bill-feedback-dialog v-model="videoBillFeedback" @on-close="closeVideoFeedback()" @on-close-cancel="closeVideoFeedbackNoBanner()"></video-bill-feedback-dialog>
        <base-snackbar v-model="videoBillFeedbackBanner" class="video-feedback-snackbar"><base-icon icon="mdi-check"></base-icon>{{$translate('videoBill.thanks')}}</base-snackbar>
    </div>
</template>

<script>
import _ from 'lodash';
import Account from './../models/Account';
import Provider from './../models/Provider';
import AccountInfoCard from './AccountInfoCard.vue';
import AccountInfo from './AccountInfo.vue';
import BillSummaryCostSummary from './BillSummaryCostSummary.vue';
import CardHeader from './CardHeader.vue';
import ProviderLogo from './ProviderLogo.vue';
import AppointmentInfoCard from './AppointmentInfoCard.vue';
import SubAccountServicesCard from './SubAccountServicesCard.vue';
import SubAccountDetailsSheet from './SubAccountDetailsSheet.vue';
import SundaySkyPlayer from './SundaySkyPlayer.vue';
import BillSummarySubAccountCard from './BillSummarySubAccountCard.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseDialog from './BaseDialog.vue';
import VideoBillFeedbackDialog from './VideoBillFeedbackDialog.vue';
import BaseSnackbar from './BaseSnackbar.vue';
import BaseIcon from './BaseIcon.vue';

export default ({
    name: 'BillSummaryContent',
    components: {
        AccountInfo,
        AccountInfoCard,
        BillSummaryCostSummary,
        CardHeader,
        ProviderLogo,
        AppointmentInfoCard,
        SubAccountServicesCard,
        SubAccountDetailsSheet,
        SundaySkyPlayer,
        BillSummarySubAccountCard,
        BaseBottomSheet,
        BaseDialog,
        VideoBillFeedbackDialog,
        BaseSnackbar,
        BaseIcon
    },
    data: () => ({
        accountInfoBottomSheet: false,
        subAccountDetailsSheet: false,
        visibleSubAccount: null,
        subAccountServices: null,
        videoBillModal: false,
        videoBillFeedback: false,
        videoBillFeedbackBanner: false,
    }),
    computed: {
        currentBill() {
            return this.$store.getters.currentBill;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        messageProviderData() {
            return { useCurrentBill: true };
        },
        currentBillSubAccountsCount() {
            return this.currentBill.subAccounts ? this.currentBill.subAccounts.length : 0;
        },
        accountInfo() {
            return this.currentBill.accountInfo;
        },
        services() {
            return this.$store.getters.billServices;
        },
        subAccounts() {
            if (this.currentBillSubAccountsCount > 0) {
                return this.currentBill.subAccounts;
            }
            return [];
        },
        provider() {
            return new Provider(this.currentBill.providerDetails);
        },
        account() {
            // Used for showing dynamic account statuses. The bill model is not a one-to-one match, so just have to do what we can to get necessary fields
            // Long term we should make the bill fetching endpoints ge full account models for seamless matching
            return new Account({
                accountId: this.currentBill.accountInfo.accountId,
                accountNumber: this.currentBill.accountNumber,
                dueDate: this.currentBill.dueDate,
                amountDue: this.currentBill.accountBalance.amount,
                hasPendingBalance: false,
                isBadDebt: this.currentBill.accountDetails.isBadDebt,
                currentPlan: this.currentBill.accountDetails.currentPlan,
                financedBy: this.currentBill.accountDetails.financedBy,
                approvedFinanceApplication: this.currentBill.accountDetails.approvedFinanceApplication,
                canManageFinancedAccount: this.currentBill.accountDetails.canManageFinancedAccount,
                secureCode: this.currentBill.secureCode,
                promiseToPayPlan: this.currentBill.accountDetails.promiseToPayPlan,
            }, this.provider);
        },
        shouldShowBillDetailsLabel() {
            const appointmentInfo = this.getAppointmentInfo();
            return (this.services && this.services.length) || (appointmentInfo && Object.keys(appointmentInfo).length) || this.currentBillSubAccountsCount > 1;
        },
        sundaySkyPosterUrl() {
            if (this.currentBill) {
                return this.currentBill.sundaySkyPosterUrl;
            }
            return null;
        },
        sundaySkyProgramId() {
            if (this.currentBill) {
                return this.provider.features.indexOf('showSundaySkyInBillSummary') > -1 ? this.currentBill.sundaySkyProgramId : null
            }
            return null;
        },
    },
    methods: {
        getAppointmentInfo() {
            if (this.currentBillSubAccountsCount > 0) {
                return this.currentBill.subAccounts[0].appointmentInfo;
            }
            return null;
        },
        requireAuthedUserForLink(state, params, canShowGuestPay, guestPayVoucherNumber) {
            const authParams = {
                canShowGuestPay: Boolean(canShowGuestPay),
                guestPayVoucherNumber,
                state: state,
                params,
                bill: {
                    amount: this.currentBill.billAmount,
                    secureCode: this.currentBill.secureCode
                }
            };
            const onAuthSuccess = () => {
                this.getUserAndLink(state, params);
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
        getUserAndLink(state, params) {
            return this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode }).then(() => {
                if (this.provider.requiresRedirectToBranded) {
                    this.emitter.emit('redirect:tab', {
                        provider: this.provider,
                            context: {
                                stateGo: {
                                    to: state,
                                    toParams: params,
                                },
                            },
                        });
                } else {
                    this.$router.replace({
                        name: state,
                        params: params,
                    });
                }
            }).catch((message) => {
                console.error(message);
            });
        },
        composeMessage() {
            this.closeBottomSheets();
            let state = 'BillSummary';
            let params = {
                secureCode: this.currentBill.secureCode,
            };
            let emit = ['message:compose', { secureCode: this.currentBill.secureCode }];
            const authParams = {
                state, params, emit,
                bill: {
                    amount: this.currentBill.billAmount,
                    secureCode: this.currentBill.secureCode
                }
            };
            const onAuthSuccess = () => {
                this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode }).then(() => {
                    if (this.provider.requiresRedirectToBranded) {
                        this.emitter.emit('redirect:tab', {
                            provider: this.provider,
                            emit: emit,
                            stateGo: {
                                to: state,
                                toParams: params
                            },
                        });
                    } else {
                        this.emitter.emit('message:compose', this.messageProviderData);
                    }
                }).catch((message) => {
                    console.error(message);
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
        viewBill(secureCode) {
            this.$store.commit('setCurrentPaymentData', {provider: this.provider, account: this.account, estimate: null, subAccount: this.visibleSubAccount});
            this.closeBottomSheets();
            let state = 'BillSummary';
            const authParams = {
                canShowGuestPay: true, state,
                bill: {
                    amount: this.currentBill.billAmount,
                    secureCode: this.currentBill.secureCode
                }
            };
            const onAuthSuccess = () => {
                this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode });
                this.$store.dispatch('openPdf', secureCode);
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
        watchStatementOverview() {
            sessionStorage.setItem("bill", JSON.stringify(this.currentBill));
            if (this.sundaySkyProgramId) {
                this.videoBillModal = true;
            } else {
                console.error('Video bill clicked without program id set.');
            }
        },
        viewSubAccount(subAccount) {
            this.setSubAccountServices(subAccount);
            this.visibleSubAccount = subAccount;
            this.subAccountDetailsSheet = true;
        },
        setSubAccountServices(subAccount) {
            if (this.currentBill.details && this.currentBill.details.visits && this.currentBill.details.visits.length) {
                var visit = _.find(this.currentBill.details.visits, { voucherNumber: subAccount.subAccountNumber });
                if (visit) {
                    this.subAccountServices = visit.detailLines.filter(d => 'chrg' === d.type);
                } else {
                    this.subAccountServices = null;
                }
            }
        },
        addCosts(types, detailLines) {
            var total = 0;
            detailLines.forEach((details) => {
                if(types.includes(details.type) && details.cost) {
                    total = total + details.cost;
                }
            });
            return total;
        },
        closeBottomSheets() {
            this.accountInfoBottomSheet = false;
            this.subAccountDetailsSheet = false;
            this.emitter.emit('responsive-provider-logo-left-column-persist-close-bottom-sheet');
        },
        redirectToFinancing() {
            const authParams = {};
            const onAuthSuccess = () => {
                this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode }).then(() => {
                    this.$store.dispatch('manageFinancingAccount', this.currentBill.secureCode)
                        .then((data) => {
                            window.location.href = data.redirect_url;
                        });
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
        },
        paySubAccount() {
            this.$store.commit('setCurrentPaymentData', {provider: this.provider, account: this.account, estimate: null, subAccount: this.visibleSubAccount});
            this.closeBottomSheets();
            this.requireAuthedUserForLink('SubAccountPaymentFlowAmountStep', {
                accountId: this.account.accountId,
                providerId: this.provider.id,
                subAccountId: this.visibleSubAccount.id,
            }, true, this.visibleSubAccount.subAccountNumber);
        },
        closeVideo() {
            this.videoBillModal = false;
            this.videoBillFeedback = true;
        },
        closeVideoFeedback() {
            this.videoBillFeedback = false;
            this.videoBillFeedbackBanner = true;
        },
        closeVideoFeedbackNoBanner() {
            this.videoBillFeedback = false;
        },
        viewPaymentOptions() {
            this.$store.commit('setCurrentPaymentData', {provider: this.provider, account: this.account, estimate: null, subAccount: null});
            this.requireAuthedUserForLink('AccountPaymentFlowAmountStep', {
                accountId: this.account.accountId, 
                providerId: this.provider.id,
            }, true);
        },
    },
    mounted() {
        this.emitter.on('responsive-provider-logo-left-column-persist-compose-message', this.composeMessage);
        if (this.currentBill) {
            this.provider.canMessage = this.provider.features.indexOf('messaging') > -1;
            this.$store.commit('setCurrentProvider', this.provider);
            if (this.currentUser) {
                this.$store.dispatch('linkBill', { amount: this.currentBill.billAmount, sCode: this.currentBill.secureCode });
            }
        } else {
            this.$router.push({
                name: 'Landing',
            });
        }
    },
    created() {},
    destroyed() {
        this.emitter.off('responsive-provider-logo-left-column-persist-compose-message', this.composeMessage);
    },
});
</script>
<style lang="scss">
@import './../styles/variables.scss';
.bill-summary-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0 0 0;
    span.vuetify.label {
        color:$global-color-font-new;
        font-size: 2.4rem;
        width: 100%;
        margin-left: 1.4rem;
        @include wider-than-tablet {
            margin-left: 0.9rem;
            font-size: 3.4rem;
        }
        &:hover::before, &:active::before {
            opacity: 0;
        }
    }
}
.bill-summary-content {
    padding-bottom: 1.6rem;
}
</style>